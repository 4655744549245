import produce, { Draft } from "immer";
import { getType } from "typesafe-actions";
import { RootAction } from "../actions";
import { CognitoState } from "../@types/cognito";
import { cognitoActions, cognitoActionsAsync } from "../actions/cognitoActions";
// import { navigate } from "@reach/router";
// import { navigate } from "gatsby";


const initialCognitoState: CognitoState = {
    user: null
}

const cognitoReducer = produce(
    (draft: CognitoState, action: RootAction) => {
        switch (action.type) {
            case getType(cognitoActions.init):
                {
                    draft.user = action.payload;
                }
                return;
            case getType(cognitoActionsAsync.init.success):
                {
                    draft.user = action.payload;
                }
                return;
            case getType(cognitoActionsAsync.init.failure):
                {
                    // if (typeof window !== "undefined") {console.log("REDUCER HEREWEQE");navigate('/login')};
                }
                return;
        }
    },
    initialCognitoState
);

export default cognitoReducer;