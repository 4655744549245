import { Epic } from "redux-observable";
import { switchMap, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { IState, Dependencies } from "../@types";
import { RootAction } from "../actions";
import { isActionOf } from "typesafe-actions";
import { formsActions, formsActionsAsync } from "../actions/formsActions";
import { Auth } from "aws-amplify";


export const createAnalysisEpic: Epic<
    RootAction,
    RootAction,
    IState,
    Dependencies
> = (action$, state$, dependencies) => {
    return action$.pipe(
        filter(isActionOf(formsActionsAsync.createAnalysis.request)),
        switchMap(action => {
            return new Observable<RootAction>(observer => {
                const state = state$.value as IState;
                const { apiGatewayUrl } = state.constants;
                const userId = state.users.user?.userId;
                const { classificationId } = action.payload;
                async function createAnalysis() {
                    const session = await Auth.currentSession();
                    const token = session.getIdToken().getJwtToken();

                    if (!userId) throw new Error("No user");
                    const analysis = await dependencies.formsApi.createAnalysis({
                        apiGatewayUrl,
                        token,
                        userId,
                        classificationId,
                    });
                    // console.log(analysis);
                    observer.next(formsActionsAsync.createAnalysis.success({ analysis }));
                }
                createAnalysis().catch(error => {
                    console.log(error);
                    observer.next(formsActionsAsync.createAnalysis.failure({ error }));
                });
            });
        })
    );
}