import {
    createAction,
    createAsyncAction
} from "typesafe-actions";
import {
    Analysis,
} from "../@types/forms";


export const analysesActions = {};

export const analysesActionsAsync = {
    getAnalyses: createAsyncAction(
        '@analyses/getAnalyses/request',
        '@analyses/getAnalyses/success',
        '@analyses/getAnalyses/failure',
    )<
        {},
        { analyses: Analysis[] },
        {}
    >(),
    getAnalysis: createAsyncAction(
        '@analyses/getAnalysis/request',
        '@analyses/getAnalysis/success',
        '@analyses/getAnalysis/failure',
    )<
        { analysisId: string },
        { analysis: Analysis },
        {}
    >(),
    getAnalysesCounts: createAsyncAction(
        '@analyses/getAnalysesCounts/request',
        '@analyses/getAnalysesCounts/success',
        '@analyses/getAnalysesCounts/failure',
    )<
        {
            userIds?: string[],
            classificationIds?: string[],
            statuses?: string[],
            groupBys?: string[]
        },
        { analysesCounts: any },
        {}
    >()
}