import { combineReducers } from "redux";
import cognitoReducer from "./cognitoReducer";
import constantsReducer from "./constantsReducer";
import usersReducer from "./usersReducer";
import clientsReducer from "./clientsReducer";
import formsReducer from "./formsReducer";
import reportsReducer from "./reportsReducer";
import analysesReducer from "./analysesReducer";
import appReducer from "./appReducer";


const rootReducer = combineReducers({
    app: appReducer,
    cognito: cognitoReducer,
    constants: constantsReducer,
    users: usersReducer,
    clients: clientsReducer,
    forms: formsReducer,
    reports: reportsReducer,
    analyses: analysesReducer,
});

export default rootReducer;