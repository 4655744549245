import { Epic } from "redux-observable";
import { switchMap, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { IState, Dependencies } from "../@types";
import { RootAction } from "../actions";
import { isActionOf } from "typesafe-actions";
import { formsActions, formsActionsAsync } from "../actions/formsActions";
import { analysesActionsAsync } from "../actions/analysesActions";
import { Auth } from "aws-amplify";
import { Analysis, Classification } from "../@types/forms";
import { AnalysisResponse } from "../../utils/AnalysisResponse";
import { AnalysisResponseTERA } from "../../utils/AnalysisResponseTERA";
const FormulaParser = require("hot-formula-parser").Parser;

const parser = new FormulaParser();


const autofill = (classification: Classification, analysis: Analysis) => {
    // console.log("CLASSIFICATION", classification);
    // console.log("ANALYSIS", analysis);
    const majorType = classification && classification.majorType;
    let analysisResponse: any;
    // console.log("MAJOR TYPE", majorType);
    switch(majorType) {
        case 'AHVVA':
            analysisResponse = AnalysisResponse(analysis?.response);
            break;
        case 'TERA':
            analysisResponse = AnalysisResponseTERA(analysis?.response);
            break;
        default:
            analysisResponse = AnalysisResponse(analysis?.response);
            break;
    }
    // console.log("ANALYSISRESPONSE GET",analysisResponse.get());
    if (!classification.surveyData) return;
    // console.log("SURVEY",classification.surveyData);
    Object.values(classification.surveyData).forEach(formView => {
        formView.components.forEach(component => {
            // console.log("COMPONENT", component);
            if (component.auto == '') return;
            // console.log("P1", component.auto);
            const re = /\$\{\w*\}/g;
            const matched = re.exec(component.auto) || "";
            if (matched && matched[0]) {
                const dataKey = matched[0].replace("${", "").replace("}", "");
                // console.log("DATA KEY", dataKey);
                const keyAnswer = analysisResponse.getData(dataKey);
                // console.log("KEY ANSWER", keyAnswer);
                // console.log("P2", dataKey, keyAnswer);
                if (typeof keyAnswer == "object") {
                    const stringifiedAnswer = `'${JSON.stringify(keyAnswer)}'`;
                    const newstring = component.auto.replace(matched[0], stringifiedAnswer);
                    // console.log("STRINGIFIED KEY ANSWER", stringifiedAnswer);
                    // console.log("COMPONENT AUTO", component.auto);
                    // console.log("NEW STRING", newstring);
                    // console.log("PARSER LOG NEW STRING", parser.parse(newstring));
                    const result = parser.parse(newstring).result;
                    // console.log("RESULT",result);
                    // console.log("P3", stringifiedAnswer, component.auto, newstring, parser.parse(newstring), result);
                    if (result !== '') {
                        // console.log("P4", JSON.parse(result));
                        // console.log("RESULT PARSED", JSON.parse(result));
                        const currentKey = component.key;
                        const format = 'single_answer';
                        let parsedResult;
                        try {
                            parsedResult = JSON.parse(result);
                        } catch (e) {
                            parsedResult = result;
                        }
                        const questionData = component.views.question;
                        const questionText = questionData.view.data.default;
                        analysisResponse.setData(currentKey, parsedResult, format, questionText);
                    } 
                    // if (result !== '') 
                }
            }
        });
    });
    analysis.response = analysisResponse.get();
}

export const submitAnalysisEpic: Epic<
    RootAction,
    RootAction,
    IState,
    Dependencies
> = (action$, state$, dependencies) => {
    return action$.pipe(
        filter(isActionOf(formsActionsAsync.submitAnalysis.request)),
        switchMap(action => {
            return new Observable<RootAction>(observer => {
                const state = state$.value as IState;
                const { apiGatewayUrl } = state.constants;
                const { status } = action.payload;
                async function submitAnalysis() {
                    const session = await Auth.currentSession();
                    const token = session.getIdToken().getJwtToken();

                    if (!state.forms.currentAnalysis) throw new Error("No analysis");

                    let analysis = JSON.parse(JSON.stringify(state.forms.currentAnalysis));

                    // Run through form to autofill empty mandatory fields
                    const classifications = state.forms.classifications;
                    const classification = classifications[analysis.classification_id];
                    // console.log("ANALYSIS PRE AUTOFILL", JSON.parse(JSON.stringify(analysis)));
                    autofill(classification, analysis);
                    // console.log("ANALYSIS POST AUTOFILL", JSON.parse(JSON.stringify(analysis)));

                    // If AHVVA title answered, set to description
                    if (analysis.response && analysis.response.project_name)
                        analysis.description = analysis.response.project_name;
                    // If TERA title answered, set to description
                    if (
                        analysis.response
                        && analysis.response.Q3
                        && analysis.response.Q3.answer[0]
                    ) analysis.description = analysis.response.Q3.answer[0];
                    if (status !== undefined) analysis.status = status; // Set status INPUT_RECEIVED

                    const updatedAnalysis = await dependencies.formsApi.updateAnalysis({
                        apiGatewayUrl,
                        token,
                        analysis,
                    });
                    observer.next(formsActionsAsync.submitAnalysis.success({ analysis }));
                    // Update analyses
                    observer.next(analysesActionsAsync.getAnalyses.request({}));
                }
                submitAnalysis().catch(error => {
                    console.log(error);
                    observer.next(formsActionsAsync.submitAnalysis.failure({ error }));
                });
            });
        })
    );
}