import { Epic } from "redux-observable";
import { switchMap, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { IState, Dependencies } from "../@types";
import { RootAction } from "../actions";
import { isActionOf } from "typesafe-actions";
import { Auth } from "aws-amplify";
import { reportsActionsAsync } from "../actions/reportsActions";
import { usersActionsAsync } from "../actions/usersActions";


export const getReportsEpic: Epic<
    RootAction,
    RootAction,
    IState,
    Dependencies
> = (action$, state$, dependencies) => {
    return action$.pipe(
        filter(isActionOf([
            reportsActionsAsync.getReports.request,
            usersActionsAsync.userInit.success
        ])),
        switchMap(action => {
            return new Observable<RootAction>(observer => {
                const state = state$.value as IState;
                const { apiGatewayUrl } = state.constants;
                const user = state.users.user;
                // const { userId, clientId } = action.payload;
                async function getReports() {
                    const session = await Auth.currentSession();
                    const token = session.getIdToken().getJwtToken();

                    if (!user) throw "No user";

                    let reports;

                    if (user.clientId && user.role !== 'user') {
                        reports = await dependencies.reportsApi.getReportsByClientId({
                            apiGatewayUrl,
                            token,
                            clientId: user.clientId
                        });
                    } else {
                        reports = await dependencies.reportsApi.getReportsByUserId({
                            apiGatewayUrl,
                            token,
                            userId: user.userId
                        });
                    }
                    observer.next(reportsActionsAsync.getReports.success({ reports }));
                }
                getReports().catch(error => {
                    console.log(error);
                    observer.next(reportsActionsAsync.getReports.failure({ error }));
                });
            });
        })
    );
}