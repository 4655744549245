import produce, { Draft } from "immer";
import { getType } from "typesafe-actions";
import { RootAction } from "../actions";
import { AppState } from "../@types/app";
import { appActions } from "../actions/appActions";


const initialAppState: AppState = {
    message: {
        message: "",
        severity: "info"
    },
}

const appReducer = produce(
    (draft: AppState, action: RootAction) => {
        switch (action.type) {
            case getType(appActions.setMessage):
                {
                    draft.message = action.payload;
                }
                return;
        }
    },
    initialAppState
);

export default appReducer;