import { Epic } from "redux-observable";
import { switchMap, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { IState, Dependencies } from "../@types";
import { RootAction } from "../actions";
import { isActionOf } from "typesafe-actions";
import { Auth } from "aws-amplify";
import { analysesActionsAsync } from "../actions/analysesActions";
import { usersActionsAsync } from "../actions/usersActions";
import { Analysis } from "../@types/forms";
import { USER_TYPES } from "../../constants";
import { getUserType } from "../../utils/getUserType";


export const getAnalysesEpic: Epic<
    RootAction,
    RootAction,
    IState,
    Dependencies
> = (action$, state$, dependencies) => {
    return action$.pipe(
        filter(isActionOf([
            analysesActionsAsync.getAnalyses.request,
            usersActionsAsync.userInit.success
        ])),
        switchMap(action => {
            return new Observable<RootAction>(observer => {
                const state = state$.value as IState;
                const { apiGatewayUrl } = state.constants;
                const user = state.users.user;
                async function getAnalyses() {
                    const session = await Auth.currentSession();
                    const token = session.getIdToken().getJwtToken();

                    if (!user) throw "No user";

                    let analyses: Analysis[] = [];
                    // if (user.role !== "super")
                    if (user.clientId && user.role !== 'user') {
                        analyses = await dependencies.analysesApi.getAnalysisByClientId({
                            apiGatewayUrl,
                            token,
                            clientId: user.clientId
                        });
                    } else {
                        analyses = await dependencies.analysesApi.getAnalysesByUserId({
                            apiGatewayUrl,
                            token,
                            userId: user.userId
                        });
                    }
                    // const analyses = user.clientId ? await dependencies.analysesApi.getAnalysisByClientId({
                    //     apiGatewayUrl,
                    //     token,
                    //     clientId: user.clientId
                    // }) : await dependencies.analysesApi.getAnalysesByUserId({
                    //     apiGatewayUrl,
                    //     token,
                    //     userId: user.userId
                    // });
                    observer.next(analysesActionsAsync.getAnalyses.success({ analyses }));
                }
                getAnalyses().catch(error => {
                    console.log(error);
                    observer.next(analysesActionsAsync.getAnalyses.failure({ error }));
                });
            });
        })
    );
}