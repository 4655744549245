import {
    createAction,
    createAsyncAction
} from "typesafe-actions";


export const cognitoActions = {
    init: createAction(
        '@cognito/init',
        (user) => user
    )(),
    setToken: createAction(
        '@cognito/storeToken',
        (v: string) => v
    )(),
}

export const cognitoActionsAsync = {
    init: createAsyncAction(
        '@cognito/init/request',
        '@cognito/init/success',
        '@cognito/init/failure',
    )<
        {},
        { user: any },
        {}
    >(),
}