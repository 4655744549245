import produce, { Draft } from "immer";
import { getType } from "typesafe-actions";
import { RootAction } from "../actions";
import { ClientsState, Clients } from "../@types/clients";
import { clientsActions, clientsActionsAsync } from "../actions/clientsActions";


const initialClientsState: ClientsState = {
    clients: {},
    counts: [],
}

const clientsReducer = produce(
    (draft: ClientsState, action: RootAction) => {
        switch (action.type) {
            case getType(clientsActionsAsync.getClients.success):
                {
                    let clients: Clients = {};
                    action.payload.clients.forEach(client => clients[client.clientId] = client);
                    draft.clients = clients;
                }
                return;
            case getType(clientsActionsAsync.createClient.success):
                {
                    const client = action.payload.client;
                    draft.clients[client.clientId] = client;
                }
                return;
            case getType(clientsActionsAsync.editClient.success):
                {
                    const client = action.payload.client;
                    draft.clients[client.clientId] = client;
                }
                return;
            case getType(clientsActionsAsync.deleteClient.success):
                {
                    delete draft.clients[action.payload.clientId];
                }
                return;
            case getType(clientsActionsAsync.getClientsCounts.success):
                {
                    draft.counts = action.payload.counts;
                }
                return;
            case getType(clientsActionsAsync.editClientCounts.success):
                {
                    const counts = action.payload.counts;
                    counts.forEach(count => {
                        const i = draft.counts.findIndex(c =>
                            c.classification_id === count.classification_id
                            && c.client_id === count.client_id
                        );
                        if (i >= 0) draft.counts[i] = count;
                        else draft.counts.push(count);
                        // draft.counts.map(currentCount => ({
                        //     classification_id: currentCount.classification_id,
                        //     client_id: currentCount.client_id,
                        //     remaining: currentCount.classification_id === count.classification_id
                        //         && currentCount.client_id === count.client_id
                        //         ? count.remaining : currentCount.remaining
                        // }));
                    });
                }
                return;
        }
    },
    initialClientsState
);

export default clientsReducer;