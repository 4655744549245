import { Epic } from "redux-observable";
import { switchMap, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { IState, Dependencies } from "../@types";
import { RootAction } from "../actions";
import { isActionOf } from "typesafe-actions";
import { Auth } from "aws-amplify";
import { reportsActionsAsync } from "../actions/reportsActions";
import { analysesActionsAsync } from "../actions/analysesActions";
import { usersActionsAsync } from "../actions/usersActions";


export const createReportEpic: Epic<
    RootAction,
    RootAction,
    IState,
    Dependencies
> = (action$, state$, dependencies) => {
    return action$.pipe(
        filter(isActionOf([
            reportsActionsAsync.createReport.request,
        ])),
        switchMap(action => {
            return new Observable<RootAction>(observer => {
                const state = state$.value as IState;
                const { apiGatewayUrl } = state.constants;
                const { description, analysisId } = action.payload;
                const userId = state.users.user?.userId;
                const clientId = state.users.user?.clientId;
                // const { userId, clientId } = action.payload;
                async function createReport() {
                    const session = await Auth.currentSession();
                    const token = session.getIdToken().getJwtToken();

                    if (!userId) throw "No user";

                    const report = dependencies.reportsApi.createReport({
                        apiGatewayUrl,
                        token,
                        userId,
                        description,
                        analysisId,
                        clientId: clientId || ""
                    });
                    // observer.next(reportsActionsAsync.getReports.request({}));
                    setTimeout(
                        () => observer.next(reportsActionsAsync.getReports.request({})),
                        1500
                    );
                    await report;
                    observer.next(reportsActionsAsync.createReport.success({}));
                }
                createReport().catch(error => {
                    console.log(error);
                    observer.next(reportsActionsAsync.createReport.failure({ error }));
                });
            });
        })
    );
}