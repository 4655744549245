import { Epic } from "redux-observable";
import { switchMap, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { IState, Dependencies } from "../@types";
import { RootAction } from "../actions";
import { isActionOf } from "typesafe-actions";
import { Auth } from "aws-amplify";
import { reportsActionsAsync } from "../actions/reportsActions";
import { usersActionsAsync } from "../actions/usersActions";


export const downloadReportEpic: Epic<
    RootAction,
    RootAction,
    IState,
    Dependencies
> = (action$, state$, dependencies) => {
    return action$.pipe(
        filter(isActionOf([
            reportsActionsAsync.downloadReport.request,
        ])),
        switchMap(action => {
            return new Observable<RootAction>(observer => {
                const state = state$.value as IState;
                const { apiGatewayUrl } = state.constants;
                const { reportId } = action.payload;
                // const { userId, clientId } = action.payload;
                async function downloadReport() {
                    const session = await Auth.currentSession();
                    const token = session.getIdToken().getJwtToken();

                    const reportEncoded = await dependencies.reportsApi.downloadReport({
                        apiGatewayUrl,
                        token,
                        reportId,
                    });
                    observer.next(reportsActionsAsync.downloadReport.success({}));
                }
                downloadReport().catch(error => {
                    console.log(error);
                    observer.next(reportsActionsAsync.downloadReport.failure({ error }));
                });
            });
        })
    );
}