import { Epic } from "redux-observable";
import { switchMap, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { IState, Dependencies } from "../@types";
import { RootAction } from "../actions";
import { isActionOf } from "typesafe-actions";
import { formsActionsAsync } from "../actions/formsActions";
import { cognitoActionsAsync } from "../actions/cognitoActions";
import { Auth } from "aws-amplify";


export const getClassificationsEpic: Epic<
    RootAction,
    RootAction,
    IState,
    Dependencies
> = (action$, state$, dependencies) => {
    return action$.pipe(
        filter(isActionOf(cognitoActionsAsync.init.success)),
        switchMap(action => {
            return new Observable<RootAction>(observer => {
                const state = state$.value as IState;
                const { apiGatewayUrl } = state.constants;
                async function getClassifications() {
                    const session = await Auth.currentSession();
                    const token = session.getIdToken().getJwtToken();

                    const classifications = await dependencies.formsApi.getClassifications({
                        apiGatewayUrl,
                        token,
                    });

                    observer.next(formsActionsAsync.getClassifications.success({ classifications }));
                }
                getClassifications().catch(error => {
                    console.log(error);
                    observer.next(formsActionsAsync.getClassifications.failure({ error }));
                });
            });
        })
    );
}