import produce, { Draft } from "immer";
import { getType } from "typesafe-actions";
import { RootAction } from "../actions";
import { RouteComponentProps, navigate } from "@reach/router";
import { UsersState, Users } from "../@types/users";
import { usersActions, usersActionsAsync } from "../actions/usersActions";


const initialUsersState: UsersState = {
    user: null,
    users: {}
}

const usersReducer = produce(
    (draft: UsersState, action: RootAction) => {
        switch (action.type) {
            case getType(usersActionsAsync.userInit.success):
                {
                    draft.user = action.payload.user;
                }
                return;
            case getType(usersActionsAsync.getUsers.success):
                {
                    let users: Users = {};
                    action.payload.users.forEach(user => users[user.userId] = user);
                    draft.users = users;
                }
                return;
            case getType(usersActionsAsync.createUser.success):
                {
                    const user = action.payload.user;
                    draft.users[user.userId] = user;
                    navigate("/app/users");
                }
                return;
            case getType(usersActionsAsync.editUser.success):
                {
                    const user = action.payload.user;
                    draft.users[user.userId] = user;
                }
                return;
            case getType(usersActions.updateSelf):
                {
                    const user = action.payload;
                    draft.user = user;
                }
                return;
            case getType(usersActionsAsync.deleteUser.success):
                {
                    delete draft.users[action.payload.userId]
                }
                return;
        }
    },
    initialUsersState
);

export default usersReducer;