import { Epic } from "redux-observable";
import { switchMap, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { IState, Dependencies } from "../@types";
import { RootAction } from "../actions";
import { isActionOf } from "typesafe-actions";
import { Auth } from "aws-amplify";
import { analysesActionsAsync } from "../actions/analysesActions";
import { usersActionsAsync } from "../actions/usersActions";
import { formsActions } from "../actions/formsActions";
import { Analysis } from "../@types/forms";


export const getAnalysesCountsEpic: Epic<
    RootAction,
    RootAction,
    IState,
    Dependencies
> = (action$, state$, dependencies) => {
    return action$.pipe(
        filter(isActionOf([
            analysesActionsAsync.getAnalysesCounts.request,
            usersActionsAsync.getUsers.success,
        ])),
        switchMap(action => {
            return new Observable<RootAction>(observer => {
                const state = state$.value as IState;
                const { apiGatewayUrl } = state.constants;
                // const {
                //     // userIds,
                //     classificationIds,
                //     statuses,
                //     // groupBys
                // } = action.payload;
                // const classificationIds = null;
                const statuses = ["1","2","8","16"];
                const groupBys = ["classification_id","user_id"];
                // const userIds = Object.keys(state.users.users);

                async function getAnalysesCounts() {
                    const session = await Auth.currentSession();
                    const token = session.getIdToken().getJwtToken();

                    const analysesCounts = await dependencies.analysesApi.getAnalysesCounts({
                        apiGatewayUrl,
                        token,
                        // userIds,
                        // classificationIds,
                        statuses,
                        groupBys
                    });
                    // console.log(analysesCounts);
                    observer.next(analysesActionsAsync.getAnalysesCounts.success({ analysesCounts: analysesCounts.summary }));
                }
                getAnalysesCounts().catch(error => {
                    console.log(error);
                    observer.next(analysesActionsAsync.getAnalysesCounts.failure({ error }));
                });
            });
        })
    );
}