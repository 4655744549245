import {
    createAction,
    createAsyncAction
} from "typesafe-actions";
import { User } from "../@types/users";


export const usersActions = {
    updateSelf: createAction(
        '@forms/updateSelf',
        (user: User) => user
    )(),
}

export const usersActionsAsync = {
    userInit: createAsyncAction(
        '@users/userInit/request',
        '@users/userInit/success',
        '@users/userInit/failure',
    )<
        {},
        { user: User },
        {}
    >(),
    getUsers: createAsyncAction(
        '@users/getUsers/request',
        '@users/getUsers/success',
        '@users/getUsers/failure',
    )<
        {},
        { users: User[] },
        {}
    >(),
    createUser: createAsyncAction(
        '@users/createUser/request',
        '@users/createUser/success',
        '@users/createUser/failure',
    )<
        { user: Partial<User>, password: string },
        { user: User },
        {}
    >(),
    editUser: createAsyncAction(
        '@users/editUser/request',
        '@users/editUser/success',
        '@users/editUser/failure',
    )<
        { userId: string, user: Partial<User> },
        { user: User },
        {}
    >(),
    resetPassword: createAsyncAction(
        '@users/resetPassword/request',
        '@users/resetPassword/success',
        '@users/resetPassword/failure',
    )<
        { userId: string, password: string },
        {},
        {}
    >(),
    deleteUser: createAsyncAction(
        '@users/deleteUser/request',
        '@users/deleteUser/success',
        '@users/deleteUser/failure',
    )<
        { userId: string },
        { userId: string },
        {}
    >(),
}