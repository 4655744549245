import {
    createAction,
    createAsyncAction
} from "typesafe-actions";
import {
    FormData,
    FormControl,
    Classifications,
    Analysis,
    Coordinate,
} from "../@types/forms";
import { Report } from "../@types/reports";


export const reportsActions = {};

export const reportsActionsAsync = {
    getReports: createAsyncAction(
        '@reports/getReports/request',
        '@reports/getReports/success',
        '@reports/getReports/failure',
    )<
        {},
        { reports: Report[] },
        {}
    >(),
    createReport: createAsyncAction(
        '@reports/createReport/request',
        '@reports/createReport/success',
        '@reports/createReport/failure',
    )<
        { description: string, analysisId: string },
        {},
        {}
    >(),
    downloadReport: createAsyncAction(
        '@reports/downloadReport/request',
        '@reports/downloadReport/success',
        '@reports/downloadReport/failure',
    )<
        { reportId: string },
        {},
        {}
    >()
}