import {
    createAction,
    createAsyncAction
} from "typesafe-actions";
import { Message } from "../@types/app";


export const appActions = {
    setMessage: createAction(
        '@app/setMessage',
        (message: Message) => message
    )(),
};

export const appActionsAsync = {}