

const reportsApi = {
    getReportsByClientId: async (params: {
        apiGatewayUrl: string,
        token: string,
        clientId: string,
    }) => {
        const { apiGatewayUrl, token, clientId } = params;
        const res = await fetch(
            `${apiGatewayUrl}/reports?client_id=${clientId}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            }
        );
        return await res.json();
    },
    getReportsByUserId: async (params: {
        apiGatewayUrl: string,
        token: string,
        userId: string,
    }) => {
        const { apiGatewayUrl, token, userId } = params;
        const res = await fetch(
            `${apiGatewayUrl}/reports?user_id=${userId}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            }
        );
        return await res.json();
    },
    createReport: async (params: {
        apiGatewayUrl: string,
        token: string,
        userId: string,
        analysisId: string,
        description: string,
        clientId: string,
    }) => {
        const { apiGatewayUrl, token, userId, analysisId, description, clientId } = params;
        const body = JSON.stringify({
            user_id: userId,
            description,
            analysis_id: analysisId,
            client_id: clientId,
        });
        const res = await fetch(
            `${apiGatewayUrl}/reports`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body
            }
        );
        return await res.json();
    },
    downloadReport: async (params: {
        apiGatewayUrl: string,
        token: string,
        reportId: string,
    }) => {
        const { apiGatewayUrl, token, reportId } = params;
        try {
            const res = await fetch(
                `${apiGatewayUrl}/reports?report_id=${reportId}&viewpdf=12`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/pdf",
                        "Authorization": `Bearer ${token}`
                    },
                }
            );

            if (res.status !== 200) return;
            // const blob = await res.blob();
            // const newBlob = new Blob([blob], { type: 'application/pdf' });
            // const objUrl = `data:application/pdf;base64,${JSON.parse(await newBlob.text()).body}`;
            // let link = document.createElement('a');
            // link.href = objUrl;
            // link.download = `${reportId}.pdf`;
            // link.click();
            const json = await res.json();
            let link = document.createElement('a');
            link.href = json.pdf_download_link;
            // link.download = `${reportId}.pdf`;
            link.click();
        } catch (err) {
            console.log("Failed to download", err);
        }
    },
};

export default reportsApi;