

const analysesApi = {
    getAnalysesByUserId: async (params: {
        apiGatewayUrl: string,
        token: string,
        userId: string,
    }) => {
        const { apiGatewayUrl, token, userId } = params;
        const res = await fetch(
            `${apiGatewayUrl}/analyses?user_id=${userId}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            }
        );
        return await res.json();
    },
    getAnalysisByClientId: async (params: {
        apiGatewayUrl: string,
        token: string,
        clientId: string
    }) => {
        const { apiGatewayUrl, token, clientId } = params;
        const res = await fetch(
            `${apiGatewayUrl}/analyses?client_id=${clientId}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            }
        );
        return await res.json();
    },
    getAnalysisByAnalysisId: async (params: {
        apiGatewayUrl: string,
        token: string,
        analysisId: string
    }) => {
        const { apiGatewayUrl, token, analysisId } = params;
        const res = await fetch(
            `${apiGatewayUrl}/analyses?analysis_id=${analysisId}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            }
        );
        return await res.json();
    },
    getAnalysesCounts: async (params: {
        apiGatewayUrl: string,
        token: string,
        userIds?: string[],
        classificationIds?: string[],
        statuses?: string[],
        groupBys?: string[],
    }) => {
        const { apiGatewayUrl, token, userIds, classificationIds, statuses, groupBys } = params;
        let qsArray = [];
        if (groupBys) qsArray.push(`group_bys=${encodeURIComponent(`["${groupBys.join('","')}"]`)}`);
        if (userIds) qsArray.push(`user_ids=${encodeURIComponent(`["${userIds.join('","')}"]`)}`);
        if (classificationIds) qsArray.push(`classification_ids=${encodeURIComponent(`["${classificationIds.join('","')}"]`)}`);
        if (statuses) qsArray.push(`statuses=${encodeURIComponent(`["${statuses.join('","')}"]`)}`);
        const qs = qsArray.join('&');
        const res = await fetch(
            `${apiGatewayUrl}/analyses/summary?${qs}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            }
        );
        return await res.json();
    },
};

export default analysesApi;