import produce, { Draft } from "immer";
import { getType } from "typesafe-actions";
import { RootAction } from "../actions";
import { FormsState } from "../@types/forms";
import { formsActions, formsActionsAsync } from "../actions/formsActions";
import { navigate } from "@reach/router";
import { AnalysisResponse } from "../../utils/AnalysisResponse";
import { AnalysisResponseTERA } from "../../utils/AnalysisResponseTERA";


const initialFormsState: FormsState = {
    classifications: {},
    currentAnalysis: undefined,
    control: {
        viewIndex: 0
    }
}

const formsReducer = produce(
    (draft: FormsState, action: RootAction) => {
        switch (action.type) {
            case getType(formsActions.setCurrentAnalysis):
                {
                    const analysis = action.payload;
                    draft.currentAnalysis = analysis;
                }
                return;
            case getType(formsActions.viewAhvva):
                {
                    const analysis = action.payload;
                    draft.currentAnalysis = analysis;
                    navigate(`/app/views/ahvva/${analysis.analysis_id}`);
                }
                return;
            case getType(formsActions.editAnalysis):
                {
                    const analysis = action.payload;
                    draft.currentAnalysis = analysis;
                    navigate(`/app/forms/${analysis.analysis_id}`);
                }
                return;
            case getType(formsActions.updateControl):
                {
                    draft.control = action.payload;
                }
                return;
            case getType(formsActions.updateFormResponse):
                {
                    const { dataKey, value, questionText, format } = action.payload;
                    let analysisResponse;
                    const classificationId = draft.currentAnalysis?.classification_id;
                    const classification = classificationId && draft.classifications[classificationId]
                    const majorType = classification && classification.majorType;
                    // console.log("MAJOR TYPE", majorType);
                    switch(majorType) {
                        case 'AHVVA':
                            analysisResponse = AnalysisResponse(draft.currentAnalysis?.response);
                            analysisResponse.setData(dataKey, value);
                            break;
                        case 'TERA':
                            analysisResponse = AnalysisResponseTERA(draft.currentAnalysis?.response);
                            analysisResponse.setData(dataKey, value, format, questionText);
                            break;
                        default:
                            analysisResponse = AnalysisResponse(draft.currentAnalysis?.response);
                            analysisResponse.setData(dataKey, value);
                            break;
                    }
                    // let analysisResponse = AnalysisResponse(draft.currentAnalysis?.response);
                    // console.log("UPDATE RES", dataKey, value, analysisResponse.get());
                    // analysisResponse.setData(dataKey, value);
                    if (draft.currentAnalysis) draft.currentAnalysis.response = analysisResponse.get();
                }
                return;
            case getType(formsActions.addFormResponseMapTargetSingle):
                {
                    const { coordinate } = action.payload;
                    let analysisResponse: any = AnalysisResponse(draft.currentAnalysis?.response);
                    analysisResponse.addMapTargetSingle(coordinate);
                    // console.log("ADD RES TARGET SINGLE", coordinate, analysisResponse.get());
                    if (draft.currentAnalysis) draft.currentAnalysis.response = analysisResponse.get();
                }
                return;
            case getType(formsActions.addFormResponseMapTargetSequence):
                {
                    const { coordinates } = action.payload;
                    let analysisResponse = AnalysisResponse(draft.currentAnalysis?.response);
                    analysisResponse.addMapTargetSequence(coordinates);
                    // console.log("ADD RES TARGET SEQUENCE", coordinates, analysisResponse.get());
                    if (draft.currentAnalysis) draft.currentAnalysis.response = analysisResponse.get();
                }
                return;
            case getType(formsActions.addFormResponseMapTargetUserSpecified):
                {
                    const { coordinates } = action.payload;
                    let analysisResponse = AnalysisResponse(draft.currentAnalysis?.response);
                    // console.log("ADD RES TARGET USER SPECIFIED", coordinates, analysisResponse.get());
                    analysisResponse.addMapTargetUserSpecified(coordinates);
                    if (draft.currentAnalysis) draft.currentAnalysis.response = analysisResponse.get();
                }
                return;
            case getType(formsActions.addFormResponseMapTargetPerimeter):
                {
                    const { coordinates, perimeterInterval } = action.payload;
                    let analysisResponse = AnalysisResponse(draft.currentAnalysis?.response);
                    analysisResponse.addMapTargetPerimeter(coordinates, perimeterInterval);
                    // console.log("ADD RES TARGET PERIMETER", coordinates, perimeterInterval, analysisResponse.get());
                    if (draft.currentAnalysis) draft.currentAnalysis.response = analysisResponse.get();
                }
                return;
            case getType(formsActions.addFormResponseMapBlockObstacle):
                {
                    const { coordinates } = action.payload;
                    let analysisResponse = AnalysisResponse(draft.currentAnalysis?.response);
                    analysisResponse.addMapBlockObstacle(coordinates);
                    // console.log("ADD RES BLOCK OBSTACLE", coordinates, analysisResponse.get());
                    if (draft.currentAnalysis) draft.currentAnalysis.response = analysisResponse.get();
                }
                return;
            case getType(formsActions.removeFormResponseMapFeature):
                {
                    const { coordinate } = action.payload;
                    let analysisResponse = AnalysisResponse(draft.currentAnalysis?.response);
                    analysisResponse.removeMapFeatureByFirstCoordinate(coordinate);
                    if (draft.currentAnalysis) draft.currentAnalysis.response = analysisResponse.get();
                }
                return;
            case getType(formsActionsAsync.getClassifications.success):
                {
                    const classifications = action.payload.classifications;
                    draft.classifications = classifications;
                }
                return;
            case getType(formsActionsAsync.createAnalysis.success):
                {
                    const { analysis } = action.payload;
                    // console.log("REDUCER ANALYSIS",analysis);
                    draft.currentAnalysis = analysis;
                    if (analysis.analysis_id) navigate(`/app/forms/${analysis.analysis_id}`);
                }
                return;
            case getType(formsActionsAsync.duplicateAnalysis.success):
                {
                    const { analysis } = action.payload;
                    // console.log("REDUCER ANALYSIS",analysis);
                    draft.currentAnalysis = analysis;
                    if (analysis.analysis_id) navigate(`/app/forms/${analysis.analysis_id}`);
                }
                return;
            case getType(formsActionsAsync.updateAnalysis.success):
                {
                    const { analysis } = action.payload;
                    // console.log("UPDATE ANALYSIS", analysis);
                    draft.currentAnalysis = analysis;
                }
                return;
            case getType(formsActionsAsync.deleteResultPath.success):
                {
                    const { analysis } = action.payload;
                    // console.log("DELETE PATH UPDATE ANALYSIS", analysis);
                    draft.currentAnalysis = analysis;
                }
                return;
        }
    },
    initialFormsState
);

export default formsReducer;