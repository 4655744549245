import produce, { Draft } from "immer";
import { getType } from "typesafe-actions";
import { RootAction } from "../actions";
import { RouteComponentProps, navigate } from "@reach/router";
import { ReportsState } from "../@types/reports";
import { reportsActions, reportsActionsAsync } from "../actions/reportsActions";
import { AnalysesState } from "../@types/analyses";
import { analysesActionsAsync } from "../actions/analysesActions";


const initialAnalysesState: AnalysesState = {
    analyses: [],
    counts: [],
}

const analysesReducer = produce(
    (draft: AnalysesState, action: RootAction) => {
        switch (action.type) {
            case getType(analysesActionsAsync.getAnalyses.success):
                {
                    draft.analyses = action.payload.analyses;
                }
                return;
            case getType(analysesActionsAsync.getAnalysis.success):
                {
                    const analysis = action.payload.analysis;
                    const i = draft.analyses.findIndex(a => a.analysis_id === analysis.analysis_id);
                    if (i >= 0) {
                        draft.analyses[i] = analysis;
                    } else {
                        draft.analyses.push(analysis);
                    }
                }
                return;
            case getType(analysesActionsAsync.getAnalysesCounts.success):
                {
                    const counts = action.payload.analysesCounts || [];
                    draft.counts = counts;
                }
                return;
        }
    },
    initialAnalysesState
);

export default analysesReducer;