import { Epic } from "redux-observable";
import { switchMap, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { IState, Dependencies } from "../@types";
import { RootAction } from "../actions";
import { isActionOf } from "typesafe-actions";
import { usersActions, usersActionsAsync } from "../actions/usersActions";
import { Auth } from "aws-amplify";
import { getUserType } from "../../utils/getUserType";
import { USER_TYPES } from "../../constants";


export const getUsersEpic: Epic<
    RootAction,
    RootAction,
    IState,
    Dependencies
> = (action$, state$, dependencies) => {
    return action$.pipe(
        filter(isActionOf([
            usersActionsAsync.getUsers.request,
            usersActionsAsync.userInit.success
        ])),
        switchMap(action => {
            return new Observable<RootAction>(observer => {
                const state = state$.value as IState;
                const { apiGatewayUrl } = state.constants;
                const user = state.users.user;
                async function getUsers() {
                    const session = await Auth.currentSession();
                    const token = session.getIdToken().getJwtToken();

                    if (!user) throw "No user";

                    const userType = getUserType(user.role, user.clientId);
                        const users = await dependencies.usersApi.getUsers({
                            apiGatewayUrl,
                            token
                        });
                        observer.next(usersActionsAsync.getUsers.success({ users }));
                }
                getUsers().catch(error => {
                    console.log(error);
                    observer.next(usersActionsAsync.getUsers.failure({ error }));
                });
            });
        })
    );
}