import { Epic } from "redux-observable";
import { switchMap, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { IState, Dependencies } from "../@types";
import { RootAction } from "../actions";
import { isActionOf } from "typesafe-actions";
import { usersActionsAsync } from "../actions/usersActions";
import { cognitoActionsAsync } from "../actions/cognitoActions";
import { Auth } from "aws-amplify";


export const userInitEpic: Epic<
    RootAction,
    RootAction,
    IState,
    Dependencies
> = (action$, state$, dependencies) => {
    return action$.pipe(
        filter(isActionOf([
            cognitoActionsAsync.init.success,
            usersActionsAsync.userInit.request,
        ])),
        switchMap(action => {
            return new Observable<RootAction>(observer => {
                const state = state$.value as IState;
                const { apiGatewayUrl } = state.constants;
                async function userInit() {
                    const session = await Auth.currentSession();
                    const token = session.getIdToken().getJwtToken();

                    const user = await dependencies.usersApi.getUser({
                        apiGatewayUrl,
                        token
                    });
                    observer.next(usersActionsAsync.userInit.success({ user }));
                }
                userInit().catch(error => {
                    console.log(error);
                    observer.next(usersActionsAsync.userInit.failure({ error }));
                });
            });
        })
    );
}