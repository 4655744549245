import { User } from "../@types/users";


const usersApi = {
    getUser: async (params: {
        apiGatewayUrl: string,
        token: string,
    }) => {
        const { apiGatewayUrl, token } = params;
        const res = await fetch(
            `${apiGatewayUrl}/users/me`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            }
        );
        return await res.json()
    },
    getUsers: async (params: {
        apiGatewayUrl: string,
        token: string
    }) => {
        const { apiGatewayUrl, token } = params;
        const res = await fetch(
            `${apiGatewayUrl}/users`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            }
        );
        return await res.json()
    },
    createUser: async (params: {
        apiGatewayUrl: string,
        token: string,
        user: Partial<User>,
        password: string,
    }) => {
        const { apiGatewayUrl, token, user, password } = params;
        const body = JSON.stringify({ ...user, password })
        const res = await fetch(
            `${apiGatewayUrl}/users`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body
            }
        );
        if (res.status >= 400) throw await res.text();
        return await res.json()
    },
    editUser: async (params: {
        apiGatewayUrl: string,
        token: string,
        userId: string,
        user: Partial<User>,
    }) => {
        const { apiGatewayUrl, token, userId, user } = params;
        const body = JSON.stringify(user)
        const res = await fetch(
            `${apiGatewayUrl}/users/${userId}`,
            {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body
            }
        );
        return await res.json()
    },
    resetPassword: async (params: {
        apiGatewayUrl: string,
        token: string,
        userId: string,
        password: string,
    }) => {
        const { apiGatewayUrl, token, userId, password } = params;
        const body = JSON.stringify({ password })
        const res = await fetch(
            `${apiGatewayUrl}/users/${userId}/resetpassword`,
            {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body
            }
        );
        return await res.text()
    },
    deleteUser: async (params: {
        apiGatewayUrl: string,
        token: string,
        userId: string
    }) => {
        const { apiGatewayUrl, token, userId } = params;
        const res = await fetch(
            `${apiGatewayUrl}/users/${userId}`,
            {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            }
        );
        return await res.json()
    },
    getMfaSoftwareToken: async (params: {
        apiGatewayUrl: string,
        token: string,
    }) => {
        const { apiGatewayUrl, token } = params;
        const res = await fetch(
            `${apiGatewayUrl}/users/mfa`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            }
        );
        return await res.json()
    },
    verifyMfaCode: async (params: {
        apiGatewayUrl: string,
        token: string,
        code: string
    }) => {
        const { apiGatewayUrl, token, code } = params;
        const body = JSON.stringify({ userCode: code });
        const res = await fetch(
            `${apiGatewayUrl}/users/mfa`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body,
            },
        );
        return await res.json()
    },
    updateMfa: async (params: {
        apiGatewayUrl: string,
        token: string,
        enabled: boolean
    }) => {
        const { apiGatewayUrl, token, enabled } = params;
        const body = JSON.stringify({ enabled });
        const res = await fetch(
            `${apiGatewayUrl}/users/mfa`,
            {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body
            }
        );
        return await res.text()
    }
};

export default usersApi;