import {
    FormData,
    Classifications,
    Classification,
    Analysis,
} from "../@types/forms";
import { TEST_DATA, TEST_DATA_2 } from "../../testdata";
import { AnalysisResponse } from "../../utils/AnalysisResponse";
import { TERA, AHVVA_PAS68, AHVVA_SINGLE_VEHICLE } from "../../testclassifications"


const formsApi = {
    getClassifications: async (params: {
        apiGatewayUrl: string,
        token: string,
    }) => {
        const { apiGatewayUrl, token } = params;
        const resTera = await fetch(
            `${apiGatewayUrl}/classifications?major_type=TERA`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            }
        );
        const tera = await resTera.json();
        const resAhvva = await fetch(
            `${apiGatewayUrl}/classifications?major_type=AHVVA`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            }
        );
        const ahvva = await resAhvva.json();
        let classifications: { [classificationId: string]: any } = {}
        tera.forEach((classification: any) => classifications[classification.classification_id] = {
            classificationId: classification.classification_id,
            description: classification.description,
            geoData: classification.geo_data,
            majorType: classification.major_type,
            surveyData: JSON.parse(classification.survey_data),
        });
        ahvva.forEach((classification: any) => classifications[classification.classification_id] = {
            classificationId: classification.classification_id,
            description: classification.description,
            geoData: classification.geo_data,
            majorType: classification.major_type,
            surveyData: JSON.parse(classification.survey_data),
        });
        // return await res.json();
        // const reportTypes: Classifications = {
        //     '3682a829-2e45-4a6e-a448-07fc432400ad': AHVVA_PAS68,
        //     '6ad76365-80fe-45f9-8507-3f7e8b786286': TERA,
        //     '2ca4797d-2000-4e85-aabc-dec3ad07acb1': AHVVA_SINGLE_VEHICLE
        // }

        // return reportTypes;
        return classifications;
    },
    createAnalysis: async (params: {
        apiGatewayUrl: string,
        token: string,
        userId: string,
        classificationId: string,
    }) => {
        const { apiGatewayUrl, token, userId, classificationId } = params;
        const body = JSON.stringify({
            user_id: userId,
            classification_id: classificationId,
            description: "Untitled"
        });
        const res = await fetch(
            `${apiGatewayUrl}/analyses`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body
            }
        );
        let data = await res.json();

        // SAMPLE AHVVA
        // let data = {
        //     "analysis_id":"44b3b5b6-6669-4738-990f-3ec95731fd62",
        //     "classification_id":"3682a829-2e45-4a6e-a448-07fc432400ad",
        //     "client_id":null,
        //     "date_created":"Mon, 19 Dec 2022 03:51:07 GMT",
        //     "description":"Untitled",
        //     "response":null,
        //     "result":null,
        //     "status":0,
        //     "user_id":"605b6449-8650-4907-8f5f-f80b4bc97bc3"
        // }

        // SAMPLE TERA
        // let data = {
        //     "analysis_id":"9c19443b-4e8b-49b9-855c-9adfcb7f726b",
        //     "classification_id":"6ad76365-80fe-45f9-8507-3f7e8b786286",
        //     "client_id":"80ac264d-8416-4abf-807c-8980dcd73355",
        //     "date_created":"Sun, 05 Feb 2023 20:54:27 GMT",
        //     "description":"Untitled",
        //     "response":null,
        //     "result":null,
        //     "status":0,
        //     "user_id":"605b6449-8650-4907-8f5f-f80b4bc97bc3"
        // }

        // SAMPLE AHVVA SINGLE VEHICLE
        // let data = {
        //     "analysis_id":"1777faff-a66c-407c-834d-5c01e7a612fb",
        //     "classification_id":"2ca4797d-2000-4e85-aabc-dec3ad07acb1",
        //     "client_id":null,
        //     "date_created":"Mon, 06 Feb 2023 13:31:15 GMT",
        //     "description":"Untitled",
        //     "response":null,
        //     "result":null,
        //     "status":0,
        //     "user_id":"605b6449-8650-4907-8f5f-f80b4bc97bc3"
        // }

        // if (data.response === null) data.response = AnalysisResponse().get();

        return data;
    },
    updateAnalysis: async (params: {
        apiGatewayUrl: string,
        token: string,
        analysis: Analysis,
    }) => {
        const { apiGatewayUrl, token, analysis } = params;
        const body = JSON.stringify(analysis);
        const res = await fetch(
            `${apiGatewayUrl}/analyses/${analysis.analysis_id}`,
            {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    // "X-Amz-Invocation-Type": "'Event'",
                    "Authorization": `Bearer ${token}`
                },
                body
            }
        );
        return null;
        // return await res.json();
        // return {
        //     "analysis_id":"44b3b5b6-6669-4738-990f-3ec95731fd62",
        //     "classification_id":"3682a829-2e45-4a6e-a448-07fc432400ad",
        //     "client_id":null,
        //     "date_created":"Mon, 19 Dec 2022 03:51:07 GMT",
        //     "description":"Untitled",
        //     "response":null,
        //     "result":null,
        //     "status":0,
        //     "user_id":"605b6449-8650-4907-8f5f-f80b4bc97bc3"
        // }
    },
};

export default formsApi;