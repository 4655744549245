import { Epic } from "redux-observable";
import { switchMap, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { IState, Dependencies } from "../@types";
import { RootAction } from "../actions";
import { isActionOf } from "typesafe-actions";
import { clientsActions, clientsActionsAsync } from "../actions/clientsActions";
import { formsActionsAsync } from "../actions/formsActions";
import { analysesActionsAsync } from "../actions/analysesActions";
import { usersActionsAsync } from "../actions/usersActions";
import { Auth } from "aws-amplify";
import { USER_TYPES } from "../../constants";
import { getUserType } from "../../utils/getUserType";

export const getClientsCountsEpic: Epic<
    RootAction,
    RootAction,
    IState,
    Dependencies
> = (action$, state$, dependencies) => {
    return action$.pipe(
        filter(isActionOf([
            clientsActionsAsync.getClientsCounts.request,
            clientsActionsAsync.editClientCounts.success,
            analysesActionsAsync.getAnalyses.request,
            formsActionsAsync.submitAnalysis.success,
            usersActionsAsync.userInit.success
        ])),
        switchMap(action => {
            return new Observable<RootAction>(observer => {
                const state = state$.value as IState;
                const { apiGatewayUrl } = state.constants;
                const user = state.users.user;
                async function getClientsCounts() {
                    const session = await Auth.currentSession();
                    const token = session.getIdToken().getJwtToken();

                    if (!user) throw "No user";
                    const userType = getUserType(user.role, user.clientId);

                    const counts = userType === USER_TYPES.ROLE_CUSTOMER_ADMIN 
                        || userType === USER_TYPES.ROLE_CUSTOMER_USER
                        || userType === USER_TYPES.ROLE_SUPER ? await dependencies.clientsApi.getClientsCounts({
                        apiGatewayUrl,
                        token,
                    }) : await dependencies.clientsApi.getClientsCountsByClientId({
                        apiGatewayUrl,
                        token,
                    });
                    // const counts = user.role === "super" ? await dependencies.clientsApi.getClientsCounts({
                    //     apiGatewayUrl,
                    //     token,
                    // }) : await dependencies.clientsApi.getClientsCountsByClientId({
                    //     apiGatewayUrl,
                    //     token,
                    // });
                    observer.next(clientsActionsAsync.getClientsCounts.success({ counts }));
                }
                getClientsCounts().catch(error => {
                    console.log(error);
                    observer.next(clientsActionsAsync.getClientsCounts.failure({ error }));
                });
            });
        })
    );
}