import {
    createAction,
    createAsyncAction
} from "typesafe-actions";
import {
    FormData,
    FormControl,
    Classifications,
    Analysis,
    AnalysisResponse,
    Coordinate,
} from "../@types/forms";


export const formsActions = {
    setCurrentAnalysis: createAction(
        '@forms/setCurrentAnalysis',
        (analysis: Analysis) => analysis
    )(),
    viewAhvva: createAction(
        '@forms/viewAhvva',
        (analysis: Analysis) => analysis
    )(),
    editAnalysis: createAction(
        '@forms/editAnalysis',
        (analysis: Analysis) => analysis
    )(),
    updateControl: createAction(
        '@forms/updateControl',
        (control: FormControl) => control
    )(),
    updateForm: createAction(
        '@forms/updateForm',
        (analysis: Analysis) => analysis
    )(),
    updateFormResponse: createAction(
        '@forms/updateFormResponse',
        (dataKey: string, value: any, format?: string, questionText?: string) => ({ dataKey, value, format, questionText })
    )(),
    addFormResponseMapTargetSingle: createAction(
        '@forms/addFormResponseMapTargetSingle',
        (coordinate: Coordinate) => ({ coordinate })
    )(),
    addFormResponseMapTargetSequence: createAction(
        '@forms/addFormResponseMapTargetSequence',
        (coordinates: Coordinate[]) => ({ coordinates })
    )(),
    addFormResponseMapTargetPerimeter: createAction(
        '@forms/addFormResponseMapTargetPerimeter',
        (coordinates: Coordinate[], perimeterInterval: number) => ({ coordinates, perimeterInterval })
    )(),
    addFormResponseMapTargetUserSpecified: createAction(
        '@forms/addFormResponseMapTargetUserSpecified',
        (coordinates: Coordinate[]) => ({ coordinates })
    )(),
    addFormResponseMapBlockObstacle: createAction(
        '@forms/addFormResponseMapBlockObstacle',
        (coordinates: Coordinate[]) => ({ coordinates })
    )(),
    removeFormResponseMapFeature: createAction(
        '@forms/removeFormResponseMapFeature',
        (coordinate: Coordinate) => ({ coordinate })
    )(),
    // deleteResultPath: createAction(
    //     '@forms/deleteResultPath',
    //     (dataIndex: number, vehicleIndex: number, pathIndex: number) => ({ dataIndex, vehicleIndex, pathIndex })
    // )(),
};

export const formsActionsAsync = {
    getClassifications: createAsyncAction(
        '@forms/getClassifications/request',
        '@forms/getClassifications/success',
        '@forms/getClassifications/failure',
    )<
        {},
        { classifications: Classifications },
        {}
    >(),
    createAnalysis: createAsyncAction(
        '@forms/createAnalysis/request',
        '@forms/createAnalysis/success',
        '@forms/createAnalysis/failure',
    )<
        { classificationId: string },
        { analysis: Analysis },
        {}
    >(),
    duplicateAnalysis: createAsyncAction(
        '@forms/duplicateAnalysis/request',
        '@forms/duplicateAnalysis/success',
        '@forms/duplicateAnalysis/failure',
    )<
        { classificationId: string, analysisId: string },
        { analysis: Analysis },
        {}
    >(),
    submitAnalysis: createAsyncAction(
        '@forms/submitAnalysis/request',
        '@forms/submitAnalysis/success',
        '@forms/submitAnalysis/failure',
    )<
        { status?: number },
        { analysis: Analysis },
        {}
    >(),
    updateAnalysis: createAsyncAction(
        '@forms/updateAnalysis/request',
        '@forms/updateAnalysis/success',
        '@forms/updateAnalysis/failure',
    )<
        { status?: number },
        { analysis: Analysis },
        {}
    >(),
    deleteResultPath: createAsyncAction(
        '@forms/deleteResultPath/request',
        '@forms/deleteResultPath/success',
        '@forms/deleteResultPath/failure',
    )<
        { pairId: number },
        { analysis: Analysis },
        {}
    >(),
}