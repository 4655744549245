import produce, { Draft } from "immer";
import { getType } from "typesafe-actions";
import { RootAction } from "../actions";
import { RouteComponentProps, navigate } from "@reach/router";
import { ReportsState } from "../@types/reports";
import { reportsActions, reportsActionsAsync } from "../actions/reportsActions";


const initialReportsState: ReportsState = {
    reports: []
}

const reportsReducer = produce(
    (draft: ReportsState, action: RootAction) => {
        switch (action.type) {
            case getType(reportsActionsAsync.getReports.success):
                {
                    draft.reports = action.payload.reports;
                }
                return;
        }
    },
    initialReportsState
);

export default reportsReducer;