import { Epic } from "redux-observable";
import { switchMap, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { IState, Dependencies } from "../@types";
import { RootAction } from "../actions";
import { isActionOf } from "typesafe-actions";
import { clientsActions, clientsActionsAsync } from "../actions/clientsActions";
import { usersActionsAsync } from "../actions/usersActions";
import { Auth } from "aws-amplify";
import { getUserType } from "../../utils/getUserType";
import { USER_TYPES } from "../../constants";


export const getClientsEpic: Epic<
    RootAction,
    RootAction,
    IState,
    Dependencies
> = (action$, state$, dependencies) => {
    return action$.pipe(
        filter(isActionOf([
            clientsActionsAsync.getClients.request,
            usersActionsAsync.userInit.success
        ])),
        switchMap(action => {
            return new Observable<RootAction>(observer => {
                const state = state$.value as IState;
                const { apiGatewayUrl } = state.constants;
                const user = state.users.user;
                async function getClients() {
                    const session = await Auth.currentSession();
                    const token = session.getIdToken().getJwtToken();
                    if (!user) throw "No user";

                    const userType = getUserType(user.role, user.clientId);
                    if (userType === USER_TYPES.ROLE_SUPER || userType === USER_TYPES.ROLE_CUSTOMER_ADMIN) {
                        const clients = await dependencies.clientsApi.getClients({
                            apiGatewayUrl,
                            token
                        });
                        observer.next(clientsActionsAsync.getClients.success({ clients }));
                    }
                }
                getClients().catch(error => {
                    console.log(error);
                    observer.next(clientsActionsAsync.getClients.failure({ error }));
                });
            });
        })
    );
}