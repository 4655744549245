import { Epic } from "redux-observable";
import { switchMap, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { IState, Dependencies } from "../@types";
import { RootAction } from "../actions";
import { isActionOf } from "typesafe-actions";
import { Auth } from "aws-amplify";
import { analysesActionsAsync } from "../actions/analysesActions";
import { formsActions } from "../actions/formsActions";
import { Analysis } from "../@types/forms";


export const getAnalysisEpic: Epic<
    RootAction,
    RootAction,
    IState,
    Dependencies
> = (action$, state$, dependencies) => {
    return action$.pipe(
        filter(isActionOf(analysesActionsAsync.getAnalysis.request)),
        switchMap(action => {
            return new Observable<RootAction>(observer => {
                const state = state$.value as IState;
                const { apiGatewayUrl } = state.constants;
                const { analysisId } = action.payload;
                async function getAnalysis() {
                    const session = await Auth.currentSession();
                    const token = session.getIdToken().getJwtToken();

                    const analysis = await dependencies.analysesApi.getAnalysisByAnalysisId({
                        apiGatewayUrl,
                        token,
                        analysisId
                    });
                    observer.next(analysesActionsAsync.getAnalysis.success({ analysis }));
                    observer.next(formsActions.setCurrentAnalysis(analysis));
                }
                getAnalysis().catch(error => {
                    console.log(error);
                    observer.next(analysesActionsAsync.getAnalysis.failure({ error }));
                });
            });
        })
    );
}