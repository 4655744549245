import { Epic } from "redux-observable";
import { switchMap, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { IState, Dependencies } from "../@types";
import { RootAction } from "../actions";
import { isActionOf } from "typesafe-actions";
import { clientsActions, clientsActionsAsync } from "../actions/clientsActions";
import { Auth } from "aws-amplify";


export const editClientEpic: Epic<
    RootAction,
    RootAction,
    IState,
    Dependencies
> = (action$, state$, dependencies) => {
    return action$.pipe(
        filter(isActionOf(clientsActionsAsync.editClient.request)),
        switchMap(action => {
            return new Observable<RootAction>(observer => {
                const state = state$.value as IState;
                const { apiGatewayUrl } = state.constants;
                const { clientId, clientName } = action.payload;
                async function editClient() {
                    const session = await Auth.currentSession();
                    const token = session.getIdToken().getJwtToken();

                    const client = await dependencies.clientsApi.editClient({
                        apiGatewayUrl,
                        token,
                        clientId,
                        clientName,
                    });
                    observer.next(clientsActionsAsync.editClient.success({ client }));
                }
                editClient().catch(error => {
                    console.log(error);
                    observer.next(clientsActionsAsync.editClient.failure({ error }));
                });
            });
        })
    );
}