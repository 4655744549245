import { INITIAL_ANALYSIS_RESPONSE } from "../constants";
import { Coordinate, MapTarget, MapBlock } from "../redux/@types/forms";


export const AnalysisResponse = (analysisResponse?: any) => {
    let response = analysisResponse && JSON.parse(JSON.stringify(analysisResponse))
        || JSON.parse(JSON.stringify(INITIAL_ANALYSIS_RESPONSE));

    const getData = (dataKey: string) => {
        if (
            response[dataKey]
            || typeof response[dataKey] === 'number'
        ) return response[dataKey];
        return null;
    }

    const setData = (dataKey :string, val: any) => {
        return response[dataKey] = val;
    }

    const addMapTargetSingle = (coordinate: Coordinate) => {
        let target: MapTarget = {
            single: coordinate,
            sequence: [],
            user_specified: [],
            perimeter: [],
            radius: 250 // path distance
        }
        return response.map.targets.push(target);
    }

    const addMapTargetSequence = (coordinates: Coordinate[]) => {
        let target: MapTarget = {
            single: {},
            sequence: coordinates,
            user_specified: [],
            perimeter: [],
            radius: 250 // path distance
        }
        return response.map.targets.push(target);
    }

    const addMapTargetUserSpecified = (coordinates: Coordinate[]) => {
        let target: MapTarget = {
            single: {},
            sequence: [],
            user_specified: coordinates,
            perimeter: [],
            radius: 250 // path distance
        }
        return response.map.targets.push(target);
    }

    const addMapTargetPerimeter = (coordinates: Coordinate[], interval: number) => {
        let target: MapTarget = {
            single: {},
            sequence: [],
            user_specified: [],
            perimeter: coordinates,
            perimeter_interval: interval,
            radius: 250 // path distance
        }
        return response.map.targets.push(target);
    }

    const removeMapFeatureByFirstCoordinate = (coordinate: Coordinate) => {
        const targetIndex = response.map.targets.findIndex((target: MapTarget) =>
            target.single.latitude === coordinate.latitude && target.single.longitude === coordinate.longitude
            || target.sequence.length > 0 && target.sequence[0].latitude === coordinate.latitude && target.sequence[0].longitude === coordinate.longitude
            || target.user_specified.length > 0 && target.user_specified[0].latitude === coordinate.latitude && target.user_specified[0].longitude === coordinate.longitude
            || target.perimeter.length > 0 && target.perimeter[0].latitude === coordinate.latitude && target.perimeter[0].longitude === coordinate.longitude
        );
        const blockIndex = response.map.blocks.findIndex((block: MapBlock) =>
            block.obstacle.length > 0 && block.obstacle[0].latitude === coordinate.latitude && block.obstacle[0].longitude === coordinate.longitude
        );
        if (targetIndex !== -1) response.map.targets.splice(targetIndex, 1);
        else if (blockIndex !== -1) response.map.blocks.splice(blockIndex, 1);
    }

    const addMapBlockObstacle = (coordinates: Coordinate[]) => {
        let block: MapBlock = {
            obstacle: coordinates
        }
        return response.map.blocks.push(block);
    }

    const get = () => { return response }

    return {
        get,
        getData,
        setData,
        addMapTargetSingle,
        addMapTargetSequence,
        addMapTargetUserSpecified,
        addMapTargetPerimeter,
        addMapBlockObstacle,
        removeMapFeatureByFirstCoordinate,
    };
}