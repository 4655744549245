import { Epic } from "redux-observable";
import { switchMap, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { IState, Dependencies } from "../@types";
import { RootAction } from "../actions";
import { isActionOf } from "typesafe-actions";
import { clientsActions, clientsActionsAsync } from "../actions/clientsActions";
import { Auth } from "aws-amplify";


export const deleteClientEpic: Epic<
    RootAction,
    RootAction,
    IState,
    Dependencies
> = (action$, state$, dependencies) => {
    return action$.pipe(
        filter(isActionOf(clientsActionsAsync.deleteClient.request)),
        switchMap(action => {
            return new Observable<RootAction>(observer => {
                const state = state$.value as IState;
                const { apiGatewayUrl } = state.constants;
                const { clientId } = action.payload;
                async function deleteClient() {
                    const session = await Auth.currentSession();
                    const token = session.getIdToken().getJwtToken();

                    const deletedClientId = await dependencies.clientsApi.deleteClient({
                        apiGatewayUrl,
                        token,
                        clientId
                    });
                    observer.next(clientsActionsAsync.deleteClient.success({ clientId }));
                }
                deleteClient().catch(error => {
                    console.log(error);
                    observer.next(clientsActionsAsync.deleteClient.failure({ error }));
                });
            });
        })
    );
}