export const MIN_PASSWORD_LENGTH = 15; // Characters

export const MIN_ZOOM = 0;
export const MAX_ZOOM = 22;
export const MAX_NATIVE_ZOOM = 19;
export const MAX_AHVVA_PATHS = 72;
export const MIN_REMAINING_DISTANCE = 100;

export const BARRIER_MAX_KINETIC_ENERGY = {
  RATING_1: 44000,
  RATING_2: 275000,
  RATING_3: 222000,
  RATING_4: 667000,
  RATING_5: 2667000,
}
export const MAP_FEATURE_COLORS = {
  HYBRID: "#1af018",
  MANUAL: "blue",
  PERIMETER: "red",
  OBSTACLE: "red",
}

export const USER_TYPES = {
  ROLE_SUPER: "super",
  ROLE_CUSTOMER_ADMIN: "customer_admin",
  ROLE_CUSTOMER_USER: "customer_user",
  ROLE_CLIENT_ADMIN: "client_admin",
  ROLE_CLIENT_USER: "client_user"
}

export const ANALYSIS_STATUS = {
  STARTED: 0,
  INPUT_RECEIVED: 1,
  IN_PROGRESS: 2,
  FAILED: 4,
  COMPLETE: 8,
  ARCHIVED: 16,
}
export const ANALYSIS_STATUS_TEXT = {
  "0": "Started",
  "1": "Input received",
  "2": "In progress",
  "4": "Failed",
  "8": "Complete",
  "16": "Archived",
}

export const REPORT_STATUS = {
  STARTED: 0,
  IN_PROGRESS: 1,
  FAILED: 2,
  COMPLETE: 4,
  ARCHIVED: 8,
}
export const REPORT_STATUS_TEXT = {
  "0": "Started",
  "1": "In progress",
  "2": "Failed",
  "4": "Complete",
  "8": "Archived",
}

export const INITIAL_ANALYSIS_RESPONSE = {
  "req_id": "",
  "project_name": "",
  "id_token": "",
  "user_name": "",
  "user_email": "",
  "top_results": null,
  "remaining_distance": null,
  "map": {
    "targets": [],
    "blocks": []
  }
}
export const INITIAL_ANALYSIS_RESPONSE_TERA = {
  "req_id": "",
  "report_type": "tera_default",
  "assessment_type": "Tera"
}

export const INITIAL_ANALYSIS_RESULT = {
  "data": [
    {
      "target_num": 0,
      "target": [],
      "target_type": "single",
      "radius": 250.0,
      "target_address": "",
      "perimeter_interval": null,
      "blocks": [],
      "vehicle_list": [
        {
          "name": "1500 kg car (M1)",
          "vehicle_num": 0,
          "weight": 1500.0,
          "acceleration": 3.0,
          "initial_speed": 0.0,
          "top_speed": 112.0,
          "coeff_fric": 0.7,
          "deceleration": -4.5,
        },
        {
          "name": "2500 kg 4x4 pick up (M2)",
          "vehicle_num": 1,
          "weight": 2500.0,
          "acceleration": 3.0,
          "initial_speed": 0.0,
          "top_speed": 112.0,
          "coeff_fric": 0.7,
          "deceleration": -4.5,
        },
        {
          "name": "3500 kg flat bed (N1)",
          "vehicle_num": 2,
          "weight": 3500.0,
          "acceleration": 2.0,
          "initial_speed": 0.0,
          "top_speed": 96.0,
          "coeff_fric": 0.7,
          "deceleration": -4.5,
        },
        {
          "name": "7500 kg 2-axle rigid (N2)",
          "vehicle_num": 3,
          "weight": 7500.0,
          "acceleration": 4.5,
          "initial_speed": 0.0,
          "top_speed": 64.0,
          "coeff_fric": 0.7,
          "deceleration": -4.5,
        },
        {
          "name": "18000 kg 2-axle rigid (N3)",
          "vehicle_num": 4,
          "weight": 18000.0,
          "acceleration": 2.0,
          "initial_speed": 0.0,
          "top_speed": 80.0,
          "coeff_fric": 0.7,
          "deceleration": -4.5,
        },
        {
          "name": "30000 kg 4-axle rigid (N3)",
          "vehicle_num": 5,
          "weight": 30000.0,
          "acceleration": 2.0,
          "initial_speed": 0.0,
          "top_speed": 80.0,
          "coeff_fric": 0.7,
          "deceleration": -4.5,
        }
      ]
    },
  ],
  "project_name": "",
  "remaining_distance": 250.0,
  "top_results": 4,
  "total_path_dist": 1440490.25,
  "total_paths_analyse": 5874,
  "total_paths_return": 48,
  "total_targets": 1,
  "total_vehicles": 6,
  "user_email": "",
  "user_name": ""
}

