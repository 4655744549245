import {
    createStore as createReduxStore,
    applyMiddleware,
} from "redux";
import { createEpicMiddleware } from "redux-observable";
import rootReducer from "../reducers";
import rootEpic from "../epics";
import { dependencies } from "../apis";


const epicMiddleware = createEpicMiddleware({ dependencies });

const createStore = () => {
    const store = createReduxStore(rootReducer, applyMiddleware(epicMiddleware));

    epicMiddleware.run(rootEpic);

    return store;
}

export default createStore;