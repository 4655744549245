import { Epic } from "redux-observable";
import { switchMap, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { IState, Dependencies } from "../@types";
import { RootAction } from "../actions";
import { isActionOf } from "typesafe-actions";
import { formsActions, formsActionsAsync } from "../actions/formsActions";
import { analysesActionsAsync } from "../actions/analysesActions";
import { Auth } from "aws-amplify";


export const updateAnalysisEpic: Epic<
    RootAction,
    RootAction,
    IState,
    Dependencies
> = (action$, state$, dependencies) => {
    return action$.pipe(
        filter(isActionOf(formsActionsAsync.updateAnalysis.request)),
        switchMap(action => {
            return new Observable<RootAction>(observer => {
                const state = state$.value as IState;
                const { apiGatewayUrl } = state.constants;
                const { status } = action.payload;
                async function updateAnalysis() {
                    const session = await Auth.currentSession();
                    const token = session.getIdToken().getJwtToken();

                    if (!state.forms.currentAnalysis) throw new Error("No analysis");

                    let analysis = JSON.parse(JSON.stringify(state.forms.currentAnalysis));
                    // If AHVVA title answered, set to description
                    if (analysis.response && analysis.response.project_name)
                    {
                        analysis.description = analysis.response.project_name;
                    }
                    // If TERA title answered, set to description
                    if (
                        analysis.response
                        && analysis.response.Q3
                        && analysis.response.Q3.answer[0]
                    ) analysis.description = analysis.response.Q3.answer[0];
                    if (status !== undefined) analysis.status = status; // Set status INPUT_RECEIVED

                    const updatedAnalysis = await dependencies.formsApi.updateAnalysis({
                        apiGatewayUrl,
                        token,
                        analysis,
                    });
                    observer.next(formsActionsAsync.updateAnalysis.success({ analysis }));
                    // Update analyses
                    observer.next(analysesActionsAsync.getAnalyses.request({}));
                }
                updateAnalysis().catch(error => {
                    console.log(error);
                    observer.next(formsActionsAsync.updateAnalysis.failure({ error }));
                });
            });
        })
    );
}