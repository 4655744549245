import { Epic } from "redux-observable";
import { Observable } from "rxjs";
import { IState, Dependencies } from "../@types";
import { RootAction } from "../actions";
import { cognitoActions, cognitoActionsAsync } from "../actions/cognitoActions";
import { Amplify, Auth } from "aws-amplify";
import qs from "qs";


export const cognitoInitEpic: Epic<
    RootAction,
    RootAction,
    IState,
    Dependencies
> = (action$, state$, dependencies) => {
    const init = new Observable<RootAction>(observer => {
        const initialise = async () => {
            const state = state$.value as IState
            const {
                cognitoAppClientId,
                cognitoDomain,
                cognitoUserPoolId,
                awsRegion
            } = state.constants

            if (typeof window === "undefined") return;

            if (window.location.pathname.includes("report")) {
                const split = window.location.pathname.split("/")
                localStorage.setItem("directLink", JSON.stringify(split[split.length-1]));
        
                //Clear URL query
                window.history.replaceState(
                    {},
                    document.title,
                    window.location.pathname
                );
            }

            Amplify.configure({
                Auth: {
                    region: awsRegion,
                    userPoolId: cognitoUserPoolId,
                    userPoolWebClientId: cognitoAppClientId,
                    mandatorySignIn: false,
                    authenticationFlowType: 'USER_PASSWORD_AUTH',
                    oauth: {
                        domain: cognitoDomain,
                        scope: [],
                        redirectSignIn: `${window.location.origin}/app`,
                        redirectSignOut: `${window.location.origin}/login`,
                        responseType: 'token'
                    }
                }
            });

            const authUser = await Auth.currentAuthenticatedUser();

            if (!authUser) throw "No auth";
            observer.next(cognitoActionsAsync.init.success(authUser))
        }
        initialise().catch(error => {
            console.log("Error: ", error);
            observer.next(cognitoActionsAsync.init.failure({ error }));
        });
    })

    return init
}