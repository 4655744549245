import { UserRole } from "../redux/@types/users"
import { USER_TYPES } from "../constants";


export const getUserType = (userRole: UserRole, clientId?: string) => {
    if (userRole === "super" && !clientId) return USER_TYPES.ROLE_SUPER;
    else if (userRole === "admin" && !clientId) return USER_TYPES.ROLE_CUSTOMER_ADMIN;
    else if (userRole === "user" && !clientId) return USER_TYPES.ROLE_CUSTOMER_USER;
    else if (userRole === "admin" && clientId) return USER_TYPES.ROLE_CLIENT_ADMIN;
    else if (userRole === "user" && clientId) return USER_TYPES.ROLE_CLIENT_USER;
}