import { Epic } from "redux-observable";
import { switchMap, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { IState, Dependencies } from "../@types";
import { RootAction } from "../actions";
import { isActionOf } from "typesafe-actions";
import { formsActions, formsActionsAsync } from "../actions/formsActions";
import { analysesActionsAsync } from "../actions/analysesActions";
import { Auth } from "aws-amplify";


export const duplicateAnalysisEpic: Epic<
    RootAction,
    RootAction,
    IState,
    Dependencies
> = (action$, state$, dependencies) => {
    return action$.pipe(
        filter(isActionOf(formsActionsAsync.duplicateAnalysis.request)),
        switchMap(action => {
            return new Observable<RootAction>(observer => {
                const state = state$.value as IState;
                const { apiGatewayUrl } = state.constants;
                const userId = state.users.user?.userId;
                const { classificationId, analysisId } = action.payload;
                async function duplicateAnalysis() {
                    const session = await Auth.currentSession();
                    const token = session.getIdToken().getJwtToken();

                    if (!userId) throw new Error("No user");
                    let analysis = await dependencies.formsApi.createAnalysis({
                        apiGatewayUrl,
                        token,
                        userId,
                        classificationId,
                    });
                    const prevAnalysis = await dependencies.analysesApi.getAnalysisByAnalysisId({
                        apiGatewayUrl,
                        token,
                        analysisId
                    });
                    // Update analysis with old analysis' response data and name
                    if (prevAnalysis && prevAnalysis.response) {
                        let newProjectName;
                        if (prevAnalysis.response.project_name) {
                            newProjectName = `${prevAnalysis.response.project_name}`;
                            prevAnalysis.response.project_name = newProjectName; 
                        } else if (prevAnalysis.response.Q3 && prevAnalysis.response.Q3.answer[0]) {
                            newProjectName = `${prevAnalysis.response.Q3.answer[0]}` 
                            prevAnalysis.response.Q3.answer[0] = newProjectName; 
                        }
                        analysis.description = newProjectName;
                        analysis.response = prevAnalysis.response;
                    }
                    const updatedAnalysis = await dependencies.formsApi.updateAnalysis({
                        apiGatewayUrl,
                        token,
                        analysis,
                    });
                    observer.next(formsActionsAsync.duplicateAnalysis.success({ analysis }));
                    // Refresh analyses
                    observer.next(analysesActionsAsync.getAnalyses.request({}));
                }
                duplicateAnalysis().catch(error => {
                    console.log(error);
                    observer.next(formsActionsAsync.duplicateAnalysis.failure({ error }));
                });
            });
        })
    );
}