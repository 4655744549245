import { Epic } from "redux-observable";
import { switchMap, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { IState, Dependencies } from "../@types";
import { RootAction } from "../actions";
import { isActionOf } from "typesafe-actions";
import { formsActionsAsync } from "../actions/formsActions";
import { Analysis } from "../@types/forms";


export const deleteResultPathEpic: Epic<
    RootAction,
    RootAction,
    IState,
    Dependencies
> = (action$, state$, dependencies) => {
    return action$.pipe(
        filter(isActionOf(formsActionsAsync.deleteResultPath.request)),
        switchMap(action => {
            return new Observable<RootAction>(observer => {
                const state = state$.value as IState;
                const { pairId } = action.payload;
                async function deleteResultPath() {
                    if (!state.forms.currentAnalysis) throw new Error("No analysis");
                    let analysis: Analysis = JSON.parse(JSON.stringify(state.forms.currentAnalysis));
                    
                    // Remove path
                    const analysisResult = analysis.result;
                    if (!analysisResult) throw new Error("No result");
                    for (let i = 0; i < analysisResult.data.length; i++) {
                        const data = analysisResult.data[i];
                        for (let j = 0; j < data.vehicle_list.length; j++) {
                            const vehicle = data.vehicle_list[j];
                            for (let k = 0; k < vehicle.paths_list.length; k++) {
                                const path = vehicle.paths_list[k];
                                if (path.pair_id === pairId) {
                                    vehicle.paths_list.splice(k, 1);
                                    break;
                                }
                            }
                        }
                    }
                    // Renumber paths
                    let newPairId = 0;
                    for (let i = 0; i < analysisResult.data.length; i++) {
                        const data = analysisResult.data[i];
                        for (let j = 0; j < data.vehicle_list.length; j++) {
                            const vehicle = data.vehicle_list[j];
                            for (let k = 0; k < vehicle.paths_list.length; k++) {
                                const path = vehicle.paths_list[k];
                                path.pair_id = newPairId;
                                newPairId++;
                            }
                        }
                    }
                    analysisResult.total_paths_return = newPairId; // Update total paths
                    observer.next(formsActionsAsync.deleteResultPath.success({ analysis }));
                    observer.next(formsActionsAsync.updateAnalysis.request({}));
                }
                deleteResultPath().catch(error => {
                    console.log(error);
                    observer.next(formsActionsAsync.deleteResultPath.failure({ error }));
                });
            });
        })
    );
}